const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://aslonov.uz',
  title: 'B.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Bexruz',
  role: 'Back End Engineer',
  description:
    'As a Backend Developer and Engineer, I specialize in building efficient, scalable, and secure solutions. With a strong foundation in technologies like Laravel, Docker, PostgreSQL, and Redis, I focus on developing robust backend systems that power modern applications.',
  resume: 'https://drive.google.com/file/d/1pPnpgscDHnUxBL7Kzlyuin89N8mP4jMY/view?usp=sharing',
  social: {
    linkedin: 'https://www.linkedin.com/in/bexruz-aslonov/',
    gitlab: 'https://gitlab.com/aslnbxrz',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'OkeyTV',
    description:
      'OkeyTV is a media platform offering online cinema and live TV streaming. Enjoy movies, shows, and live channels—all in one place.',
    stack: ['Laravel', 'React Native'],
    sourceCode: 'https://gitlab.com/aslnbxrz/media-tv',
    livePreview: 'https://okeytv.uz',
  },
  {
    name: 'BeshTaxi',
    description:
      'A regional taxi service providing fast and reliable transportation across multiple areas. Offering easy bookings and affordable rides, it connects passengers with drivers throughout the region.',
    stack: ['Laravel', 'Flutter', 'Redis'],
    livePreview: 'https://beshtaxi.uz',
  },
  {
    name: 'BeshCRM',
    description:
      'A CRM solution that streamlines operations and enhances customer relationships, offering tools for lead tracking and workflow automation.',
    stack: ['Laravel', 'Flutter', 'Websockets'],
    livePreview: 'https://beshcrm.uz',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'CI/CD',
  'PHP',
  'Laravel',
  'API',
  'Git',
  'Redis',
  'PostgreSQL',
  'Linux',
  'Docker',
  'HTML',
  'CSS',
  'JavaScript',
  'React',
  'SASS',
  'and more...',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'bexruz.aslonov1@gmail.com',
}

export { header, about, projects, skills, contact }
